.sceenic-header {
    position: absolute;
    top: 0;
    left: 0;
    height: 90px;
    background-color: #1c1c1c;
    width: 100%;
    max-width: 100%;
    margin: 0;
}

.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
}

.logo {
    height: 90px;
    width: 90px;
}

.right-side {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logout {
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}
